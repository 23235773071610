import { Controller } from "@hotwired/stimulus"
import _ from "lodash-es";

export default class extends Controller {
  initialize() {
    this.change = _.debounce(this.change, 500).bind(this);
  }

  change() {
    const formElement = this.element instanceof HTMLInputElement ? this.element.form : this.element;
    
    formElement.requestSubmit();
  }
}
