import { Modal } from 'tailwindcss-stimulus-components';

export default class extends Modal {
  open(e) {
    super.open(e);
  }

  close(_e) {
    // Unlock the scroll and restore previous scroll position
    this.unlockScroll();

    // Hide the modal
    this.containerTarget.classList.add(this.toggleClass);

    // Remove the background
    if (this.background) { this.background.remove() }
  }
}
